import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import responsive from '@miniintern/styles/responsive';
import InstagramSnsLinkIcon from '@svg/sns-icon/link_instagram_black.svg';
import palette from '@miniintern/styles/palette';
import MiniinternLogoSVG from '@svg/logo/logo_footer.svg';
import routes from '#constants/routes';

const FooterSiteInfoBlock = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${palette.gray_400};

  .footer-wrapper-site-menu-info {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    @media (max-width: ${responsive.medium}) {
      flex-direction: column;
    }

    .footer-wrapper-site-menu-logo-box {
      display: flex;
      justify-content: space-between;
    }

    .footer-logo {
      a {
        display: inline-block;
        > svg {
          vertical-align: middle;
        }
      }
    }
    .footer-wrapper-menu {
      @media (max-width: ${responsive.medium}) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin: 24px 0 0;
      }
      .site-menu {
        display: flex;
        width: 100%;

        li + li {
          margin: 0 0 0 40px;

          @media (max-width: ${responsive.medium}) {
            margin-left: 60px;
          }

          @media (max-width: ${responsive.small}) {
            margin-left: auto;
          }
        }
        li {
          a {
            display: inline-block;
            color: ${palette.black};
            transition: all 0.3s;
            &:hover {
              color: ${palette.blue_500};
            }

            @media (max-width: ${responsive.medium}) {
              font-size: 12px;
            }

            @media (max-width: ${responsive.xxsmall}) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .sns-menu {
    display: none;
    @media (max-width: ${responsive.medium}) {
      display: flex;
      li + li {
        margin: 0 0 0 16px;
      }
      li {
        a {
          display: block;

          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .footer-wrapper-site-cs-info {
    display: flex;
    justify-content: space-between;
    margin: 25px 0 0;
    position: relative;
    z-index: 1;

    @media (max-width: ${responsive.medium}) {
      margin-top: 20px;
    }

    .cs-info-list {
      position: relative;
      li {
        display: flex;
        .service-title {
          min-width: 82px;
          font-weight: 500;
        }
        .service-detail-info {
          font-size: 12px;
          line-height: 21px;
          color: ${palette.gray_800};
          .br {
            display: none;
            @media (max-width: ${responsive.xsmall}) {
              display: block;
            }
          }
          b {
            display: inline-block;
            font-weight: 400;
            @media (max-width: ${responsive.xsmall}) {
              display: none;
            }
          }
          a {
            display: inline-block;
            font-size: 12px;
            line-height: 21px;
            color: ${palette.gray_800};
            &:hover {
              color: ${palette.blue_500};
            }
            &.page-link {
              margin: 0 0 0 4px;
              font-weight: 500;
            }
          }
        }
      }
      li + li {
        margin: 10px 0 0;
      }
    }
    .sns-menu-pc {
      display: flex;

      li + li {
        margin: 0 0 0 16px;
      }

      @media (max-width: ${responsive.medium}) {
        display: none;
      }
    }
  }
`;

const FooterSiteInfo = () => {
  return (
    <FooterSiteInfoBlock>
      <div className="footer-wrapper-site-info">
        <div className="footer-wrapper-site-menu-info">
          <div className="footer-wrapper-site-menu-logo-box">
            <Link href={routes.home} className="footer-logo">
              <MiniinternLogoSVG />
            </Link>
            <ul className="sns-menu">
              {/* <li>
                <a href="https://www.facebook.com/miniintern" target="_blank" rel="noreferrer">
                  <FacebookSnsLinkIcon />
                </a>
              </li> */}
              <li>
                <a href="https://www.instagram.com/miniintern/" target="_blank" rel="noreferrer">
                  <InstagramSnsLinkIcon />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-wrapper-menu">
            <ul className="site-menu">
              <li>
                <Link href={routes.helpcenterTerms} data-gtm-label="common_click_footer_tos">
                  이용약관
                </Link>
              </li>
              <li>
                <Link
                  href={routes.helpcenterPrivacy}
                  data-gtm-label="common_click_footer_privacypolicy"
                >
                  개인정보처리방침
                </Link>
              </li>
              <li>
                <Link href={routes.helpcenter} data-gtm-label="common_click_footer_helpcenter">
                  새로운 소식
                </Link>
              </li>
              <li>
                <Link href={routes.helpcenterQna} data-gtm-label="common_click_footer_qna">
                  자주하는 질문
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-wrapper-site-cs-info">
          <ul className="cs-info-list">
            <li>
              <p className="service-title">고객센터</p>
              <p className="service-detail-info">
                이메일 <a href="mailto: help@miniintern.com">help@miniintern.com</a>{' '}
                <br className="br" /> <b className="slash">/</b> 전화{' '}
                <a href="tel: 010-4875-3056">010-4875-3056</a>
              </p>
            </li>
            <li>
              <p className="service-title">기업 서비스</p>
              <p className="service-detail-info">
                기업 회원 페이지
                <Link
                  href={routes.withus}
                  className="page-link"
                  data-gtm-label="common_click_footer_companyuser"
                >
                  바로가기
                </Link>
              </p>
            </li>
          </ul>
          <ul className="sns-menu-pc">
            {/* <li>
              <a href="https://www.facebook.com/miniintern" target="_blank" rel="noreferrer">
                <FacebookSnsLinkIcon />
              </a>
            </li> */}
            <li>
              <a href="https://www.instagram.com/miniintern/" target="_blank" rel="noreferrer">
                <InstagramSnsLinkIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </FooterSiteInfoBlock>
  );
};

export default FooterSiteInfo;
