import useSWR, { SWRConfiguration } from 'swr';
import { NotificationType, UserInfoType } from '#types/data';
import axios from '../api/apiClient';
import { sendErrorToSentry } from '../error';

const useUserNotification = (user?: UserInfoType, options?: SWRConfiguration) => {
  const fetcher = async (url: string) => {
    try {
      const { data } = await axios.get<NotificationType[]>(url);
      return data;
    } catch (err) {
      sendErrorToSentry(err);
      throw err;
    }
  };

  const { data, mutate, error } = useSWR<NotificationType[]>(
    user ? '/api/v2/me/notifications' : null,
    fetcher,
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      ...options,
    },
  );

  return {
    notificationList: data || [],
    mutateUserNotification: mutate,
    isLoadingToGetNotifications: !data && !error,
  };
};

export default useUserNotification;
