import Link, { LinkProps } from 'next/link';
import React, { useMemo } from 'react';
import routes from '#constants/routes';
import { usePathname } from 'next/navigation';

interface IProps extends LinkProps {
  children: React.ReactNode;
  href: string;
  as?: string;
  className?: string;
  activeClassName?: string;
  prefetch?: boolean;
  activePaths?: string[];
}

const ActiveLink: React.FC<IProps> = ({
  children,
  href = routes.home,
  as,
  className = '',
  activePaths = [],
  activeClassName = 'active',
  prefetch = false,
  ...rest
}) => {
  const pathname = usePathname();
  const rootPath = (pathname: string) => pathname.split('/')[1];

  const isActivePath = useMemo(
    () =>
      (rootPath(href) !== 'auth' &&
        rootPath(href) !== 'me' &&
        rootPath(pathname) === rootPath(href)) ||
      pathname === href ||
      activePaths.includes(pathname),
    [pathname, href, activePaths],
  );

  return (
    <Link href={href} as={as} prefetch={prefetch} passHref {...rest}>
      {React.cloneElement(children as React.ReactElement<any>, {
        className: isActivePath ? `${activeClassName} ${className}` : className,
      })}
    </Link>
  );
};

export default ActiveLink;
