import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import useAuth from '#lib/hooks/useAuth';

const UserMenuItemBlock = styled.li`
  .logout-button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-size: 14px;
    padding: 0 15px;
    height: 36px;
  }
`;

interface IProps {
  text: string;
  to?: string;
  gtmLabel: string;
}

const UserMenuItem: React.FC<IProps> = ({ text, to, gtmLabel }) => {
  const { onLogout } = useAuth();
  return (
    <>
      {to ? (
        <UserMenuItemBlock>
          <Link href={to} data-gtm-label={gtmLabel}>
            {text}
          </Link>
        </UserMenuItemBlock>
      ) : (
        <UserMenuItemBlock>
          <button
            className="logout-button"
            type="button"
            onClick={onLogout}
            data-gtm-label={gtmLabel}
          >
            {text}
          </button>
        </UserMenuItemBlock>
      )}
    </>
  );
};

export default React.memo(UserMenuItem);
