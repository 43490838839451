import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import AlarmWhiteIcon from '@svg/alarm/alarm_bell-white.svg';
import AlarmIcon from '@svg/alarm/alarm_bell.svg';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';
import zIndexes from '#styles/zIndexes';
import { deleteAllNotification } from '#lib/api/user';
import Notification from './Notification';
import useUserNotification from '#lib/hooks/useUserNotification';
import useUserInfo from '#lib/hooks/useUserInfo';
import { sendErrorToSentry } from '#lib/error';
import OutsideClickHandler from 'react-outside-click-handler';
import { getScrollbarWidth } from '@miniintern/utils';
import { DEFAULT_SWIPER_OPTION } from '#lib/swiperSettings';
import { ChoiceChipGroup } from '@miniintern/ui';

const HeaderAlaramWrapper = styled.div`
  position: relative;

  .popup-template {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    @media (max-width: ${responsive.medium}) {
      position: fixed;
      top: 52px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .notification-contents-box {
    position: absolute;
    right: 0;
  }
`;

const AlarmIconWrapper = styled.div`
  margin-top: 2px;
  cursor: pointer;
  position: relative;

  @media (max-width: ${responsive.medium}) {
    svg {
      margin-top: 3px;
    }
  }
`;

const NotificationPopup = styled.div`
  width: 420px;
  overflow-y: scroll;
  background-color: ${palette.white};
  z-index: ${zIndexes.notification};
  border-radius: 5px;
  border: 1px solid ${palette.gray_400};
  box-shadow: 0px 2px 4px rgba(0, 56, 68, 0.2);
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .notification-head-box {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${palette.white};

    .notification-head {
      display: flex;
      border-bottom: 1px solid ${palette.gray_300};
      padding: 16px 20px;
      justify-content: space-between;
      align-items: center;

      .notification-head-title {
        font-size: 14px;
        font-weight: 500;
      }

      .notification-head-delete {
        font-size: 14px;
        color: ${palette.blue_500};
        cursor: pointer;
      }
    }

    .notification-tag-swiper-slide {
      display: flex;
      overflow: hidden;
      border-bottom: 1px solid ${palette.gray_300};
      padding: 0 14px;

      .swiper-wrapper {
        display: -webkit-box;

        .swiper-slide {
          margin: 14px 6px;
          width: max-content;
        }
      }
    }
  }

  .notification-contents {
    height: 360px;

    .notification-contents-inner {
      background-color: ${palette.white};
    }
  }

  .no-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: ${palette.gray_500};
  }

  @media (max-width: ${responsive.medium}) {
    position: fixed;
    width: 100%;
    top: 49px;
    right: 0;
    border: 0;
    border-top: 1px solid ${palette.gray_400};
    border-radius: 0;
    box-shadow: 0px 2px 4px rgba(0, 56, 68, 0.2);
  }
`;

const AlarmCount = styled.p<{
  count: number;
  transparentHeader: boolean;
  isScrolled: boolean;
}>`
  position: absolute;
  top: 1px;
  right: ${props => (props.count < 10 ? '6px' : '2px')};
  background-color: ${props =>
    !props.transparentHeader || props.isScrolled ? palette.black : 'white'};
  color: ${props => (!props.transparentHeader || props.isScrolled ? 'white' : palette.black)};
  font-weight: 500;
  border-radius: 2px;
  font-size: 10px;
  line-height: 10px;
  padding: 1px 2px;
`;

export const notificationsTag = [
  { label: '전체', value: 'all' },
  { label: '미니인턴', value: 'project' },
  { label: '스킬업', value: 'skillup' },
  { label: 'M클래스', value: 'event' },
  { label: '채용관', value: 'recruitment' },
  { label: '해피폴리오', value: 'happyfolio' },
  { label: '기타', value: 'etc' },
  { label: '채용관 호스트', value: 'recruitmenthost' },
];

interface IProps {
  transparentHeader: boolean;
  isScrolled: boolean;
}

const HeaderAlaram: React.FC<IProps> = ({ transparentHeader, isScrolled }) => {
  const [isShown, setIsShown] = useState(false);
  const { userInfo } = useUserInfo();
  const { notificationList, mutateUserNotification } = useUserNotification(userInfo);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const initialNotificationTag = notificationsTag[0];
  const [currentTag, setCurrentTag] =
    useState<typeof initialNotificationTag>(initialNotificationTag);
  const notificationContentsRef = useRef<HTMLDivElement>(null);

  const unReadNotificationCount = useMemo(
    () => notificationList.filter(item => !item.isRead).length,
    [notificationList],
  );

  const toggleShowStatus = () => {
    const scrollPosition = window.pageYOffset;
    const scrollWidth = getScrollbarWidth();

    setIsShown(prev => !prev);
    setCurrentTag(initialNotificationTag);

    if (!isShown) {
      setCurrentScrollPosition(scrollPosition);
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollWidth}px`;
    }

    if (isShown) {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
      window.scrollTo(0, currentScrollPosition);
    }
  };

  const onClickDeleteAllNotification = async () => {
    try {
      await deleteAllNotification();
      mutateUserNotification([], false);
    } catch (err) {
      sendErrorToSentry(err);
    }
  };

  const currentNotificationList = useMemo(() => {
    if (currentTag.value === 'all') return notificationList;

    return notificationList.filter(list => list.type === currentTag.value);
  }, [notificationList, currentTag]);

  const filteredNotificationsTag = useMemo(
    () =>
      notificationsTag.filter(tag => userInfo?.recruitmentAdmin || tag.value !== 'recruitmenthost'),
    [userInfo?.recruitmentAdmin],
  );

  useEffect(() => {
    //? 태그 변경 시, 리스트 스크롤 상단으로 올리기 위해
    notificationContentsRef.current?.scrollTo(0, 0);
  }, [currentTag]);

  return (
    <HeaderAlaramWrapper data-gtm-label="common_click_bell">
      <AlarmIconWrapper onClick={toggleShowStatus}>
        {!transparentHeader || isScrolled ? <AlarmIcon /> : <AlarmWhiteIcon />}
        {!!unReadNotificationCount && (
          <AlarmCount
            transparentHeader={transparentHeader}
            isScrolled={isScrolled}
            count={unReadNotificationCount || 0}
          >
            {unReadNotificationCount > 99 ? 99 : unReadNotificationCount}
          </AlarmCount>
        )}
      </AlarmIconWrapper>

      {isShown && (
        <div className="notification-contents-box">
          <div className="popup-template">
            <OutsideClickHandler onOutsideClick={toggleShowStatus} disabled={!isShown}>
              <NotificationPopup ref={notificationContentsRef}>
                <div className="notification-head-box">
                  <div className="notification-head">
                    <h2 className="notification-head-title">알림</h2>
                    <p
                      className="notification-head-delete"
                      role="presentation"
                      onClick={onClickDeleteAllNotification}
                    >
                      모든 알림 지우기
                    </p>
                  </div>

                  <Swiper {...DEFAULT_SWIPER_OPTION} className="notification-tag-swiper-slide">
                    <SwiperSlide>
                      <ChoiceChipGroup
                        options={filteredNotificationsTag}
                        selectedValue={currentTag.value}
                        setValue={value =>
                          setCurrentTag(
                            filteredNotificationsTag.find(tag => tag.value === value) ||
                              initialNotificationTag,
                          )
                        }
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="notification-contents">
                  {currentNotificationList.length === 0 ? (
                    <p className="no-notification">알림이 없습니다.</p>
                  ) : (
                    <div className="notification-contents-inner">
                      {currentNotificationList &&
                        currentNotificationList.map(notification => (
                          <Notification notification={notification} key={notification.id} />
                        ))}
                    </div>
                  )}
                </div>
              </NotificationPopup>
            </OutsideClickHandler>
          </div>
        </div>
      )}
    </HeaderAlaramWrapper>
  );
};

export default React.memo(HeaderAlaram);
