import React from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import palette from '@miniintern/styles/palette';
import zIndexes from '#styles/zIndexes';
import UserMenuItem from './UserMenuItem';
import routes from '#constants/routes';

const Wrapper = styled.span`
  position: absolute;
  top: 43px;
  right: 0;
  background: transparent;
  z-index: ${zIndexes.userMenu};
`;

const UserMenuListBlock = styled.ul`
  background: white;
  box-shadow: 0 5px 7px rgba(0, 56, 67, 0.2);
  padding: 12px 0;
  width: 167px;
  border: 1px solid ${palette.gray_400};
  border-radius: 5px;
  box-shadow: 0 5px 7px 0 rgba(0, 56, 68, 0.2);
  overflow: hidden;
  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    color: ${palette.gray_800};
    font-weight: 400;
    a {
      display: block;
      width: 100%;
      color: inherit;
      padding: 0 15px;
      height: 36px;
      display: flex;
      align-items: center;
    }
    &:hover {
      background: ${palette.gray_50};
    }
  }
`;

const WithusLinkWrapper = styled.li`
  border-top: 1px solid ${palette.gray_300};
  border-bottom: 1px solid ${palette.gray_300};
`;

interface IProps {
  onClickMenuToggle: () => void;
  isMiniinternOperator: boolean;
  isRecruitmentAdmin: boolean;
}

const UserMenuList: React.FC<IProps> = ({
  onClickMenuToggle,
  isMiniinternOperator,
  isRecruitmentAdmin,
}) => {
  const recruitmentUrl = process.env.RECRUITMENT_URL;
  const adminUrl = process.env.ADMIN_URL;

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        onClickMenuToggle();
      }}
    >
      <Wrapper>
        <UserMenuListBlock>
          {isMiniinternOperator && (
            <li>
              <a href={adminUrl!} target="_blank" rel="noopener noreferrer">
                관리자 홈으로 이동
              </a>
            </li>
          )}
          <UserMenuItem
            text="마이 커리어"
            to={routes.meProject}
            gtmLabel="common_click_usernavigation_mycareer"
          />
          <UserMenuItem
            text="회원정보 변경"
            to={`${routes.meAccount}?tab=basic`}
            gtmLabel="common_click_usernavigation_account"
          />
          <UserMenuItem
            text="공지사항"
            to={routes.helpcenterNotices}
            gtmLabel="common_click_usernavigation_notices"
          />
          {isRecruitmentAdmin && (
            <WithusLinkWrapper>
              <a href={recruitmentUrl} data-gtm-label="common_click_usernavigation_onlycompany">
                기업관리자
              </a>
            </WithusLinkWrapper>
          )}
          <UserMenuItem text="로그아웃" gtmLabel="common_click_usernavigation_logout" />
        </UserMenuListBlock>
      </Wrapper>
    </OutsideClickHandler>
  );
};
export default React.memo(UserMenuList);
