import React from 'react';
import styled from 'styled-components';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';
import ActiveLink from '#components/common/ActiveLink';
import BetaIcon from '@svg/skillup/beta-tag.svg';

type HeaderListItemBlockType = {
  type?: 'USER';
  name: string;
  transparentHeader: boolean;
  isScrolled: boolean;
};

const HeaderListItemBlock = styled.li<HeaderListItemBlockType>`
  display: inline-flex;
  height: 100%;
  position: relative;
  transition: all 0.3s;
  flex-shrink: 0;
  div {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    padding: 20px 16px;
    display: flex;
    align-items: center;
    transition: color 0.2s ease-in-out;
    font-weight: 400;
    color: ${props =>
      props.transparentHeader && !props.isScrolled
        ? `${palette.gray_400}`
        : props.type === 'USER'
          ? `${palette.blue_500}`
          : `${palette.gray_500}`};

    .beta-tag {
      display: inline-block;
      position: absolute;
      top: 14px;
      right: 10px;
      font-size: 0;
      z-index: 1;
      svg {
        display: inline-block;
        vertical-align: middle;
      }
    }
    @media (max-width: ${responsive.medium}) {
      display: ${props => props.name === '서비스 소개' && 'none'};
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      opacity: 0;
      background: ${palette.blue_500};
      transform: scaleX(0);
      transition: all 0.3s;
    }

    &:hover {
      color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_800 : palette.white};
      background-color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_50 : 'transparent'};

      ${props =>
        (!props.transparentHeader || props.isScrolled) &&
        `
        &:after {
          opacity: 1;
          transform: scaleX(1);
        }
      `}
    }
    &.active {
      color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_800 : palette.white};

      ${props =>
        (!props.transparentHeader || props.isScrolled) &&
        `
        &:after {
          opacity: 1;
          transform: scaleX(1);
        }
      `}
    }
  }

  a.beta {
    padding: 20px 32px 20px 16px;
  }
`;

interface IProps {
  name: string;
  to: string;
  gtmLabel: string;
  aboutAuth?: boolean;
  type?: 'USER';
  transparentHeader?: boolean;
  isScrolled: boolean;
  activePaths?: string[];
}

const HeaderListItem: React.FC<IProps> = ({
  type,
  name,
  to,
  gtmLabel,
  transparentHeader = false,
  isScrolled,
  activePaths,
}) => {
  return (
    <HeaderListItemBlock
      name={name}
      type={type}
      transparentHeader={transparentHeader}
      isScrolled={isScrolled}
    >
      <ActiveLink
        href={to}
        activeClassName="active"
        className={`${name === '스킬업' ? 'beta' : ''}`}
        data-gtm-label={gtmLabel}
        activePaths={activePaths}
      >
        <div>
          {name}
          {name === '스킬업' && (
            <span className="beta-tag">
              <BetaIcon />
            </span>
          )}
        </div>
      </ActiveLink>
    </HeaderListItemBlock>
  );
};

export default React.memo(HeaderListItem);
