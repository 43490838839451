import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import parse from 'html-react-parser';
import PopupCloseIcon from '@svg/popup/popup-X-button-24.svg';
import palette from '@miniintern/styles/palette';
import { NotificationType } from '#types/data';
import { deleteNotification, readNotification } from '#lib/api/user';
import { sendErrorToSentry } from '#lib/error';
import useUserNotification from '#lib/hooks/useUserNotification';
import useUserInfo from '#lib/hooks/useUserInfo';
import { notificationsTag } from './HeaderAlaram';

const NotificationWrapper = styled.div<{ isRead: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 20px;
  border-bottom: 1px solid ${palette.gray_300};
  cursor: pointer;
  background-color: ${({ isRead }) => (isRead ? palette.white : palette.gray_50)};

  &:hover {
    background-color: ${palette.gray_200};
  }

  .notification-header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .notification-header-type {
      font-size: 12px;
      color: ${palette.blue_500};
    }

    .notification-header-date-box {
      display: flex;
      align-items: center;
      gap: 8px;

      .notification-header-date {
        font-size: 12px;
        color: ${palette.gray_500};
      }

      .notification-delete-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .notification-content {
    display: block;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

interface IProps {
  notification: NotificationType;
}

const Notification: React.FC<IProps> = ({ notification }) => {
  const { userInfo } = useUserInfo();
  const { mutateUserNotification, notificationList } = useUserNotification(userInfo, {
    revalidateIfStale: false,
  });

  const notificationTagTitle = notificationsTag.find(tag => tag.value === notification.type)?.label;

  const onClickDeleteNotification = async (e: React.MouseEvent<HTMLElement>) => {
    if (!notificationList) return;
    const copyNotificationList = [...notificationList];

    try {
      e.stopPropagation();
      await deleteNotification(notification.id);

      mutateUserNotification(
        copyNotificationList.filter(item => item.id !== notification.id),
        false,
      );
    } catch (err) {
      sendErrorToSentry(err);
    }
  };

  const onClickNotification = async () => {
    const { destinationLink, isRead, id } = notification;
    const copyNotificationList = JSON.parse(JSON.stringify(notificationList)) as NotificationType[];

    if (!isRead) {
      try {
        await readNotification(id);

        const targetNotificationIndex = copyNotificationList.findIndex(item => item.id === id);
        copyNotificationList[targetNotificationIndex].isRead = true;

        mutateUserNotification(copyNotificationList, false);
      } catch (e) {
        sendErrorToSentry(e);
      }
    }

    if (!destinationLink) return;
    window.location.href = destinationLink;
  };

  return (
    <NotificationWrapper isRead={notification.isRead} onClick={onClickNotification}>
      <div className="notification-header-box">
        <p className="notification-header-type">{notificationTagTitle}</p>
        <div className="notification-header-date-box">
          <p className="notification-header-date">
            {format(new Date(notification.createdAt!), 'yyyy.MM.dd. HH:mm')}
          </p>
          <button className="notification-delete-button" onClick={onClickDeleteNotification}>
            <PopupCloseIcon />
          </button>
        </div>
      </div>

      <p className="notification-content" id={`notification_${notification.id}_content`}>
        {parse(notification.body)}
      </p>
    </NotificationWrapper>
  );
};

export default React.memo(Notification);
