import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import responsive from '@miniintern/styles/responsive';
import zIndexes from '#styles/zIndexes';
import HeaderList from './HeaderList';
import HeaderListItem from './HeaderListItem';
import PCHeaderLogoSVG from '@svg/logo/logo_header.svg';
import PCHeaderWhiteLogoSVG from '@svg/logo/logo-header-miniintern-white.svg';
import MobileHeaderLogoSVG from '@svg/logo/logo_header_mobile.svg';
import MobileHeaderWhiteLogoSVG from '@svg/logo/logo_header_mobile-white.svg';
import HeaderRightMenu from './HeaderRightMenu';
import routes from '#constants/routes';
import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

const Sidebar = dynamic(() => import('./Sidebar'), { ssr: false });

const HeaderBlock = styled.header<{
  isSticky?: boolean;
  isTransparentHeader: boolean;
  isScrolled: boolean;
}>`
  display: flex;
  justify-content: center;
  position: relative;
  height: 61px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 56, 68, 0.1);

  ${props =>
    props.isSticky &&
    css`
      position: sticky;
      top: -1px;
      z-index: ${zIndexes.header};
    `};

  ${props =>
    props.isTransparentHeader &&
    css`
      position: sticky;
      top: -1px;
      margin: 0 0 -61px;
      background-color: ${props.isScrolled ? 'white' : 'transparent'};
      border-bottom-color: ${props.isScrolled ? 'rgba(0, 56, 68, 0.1)' : 'transparent'};
      z-index: ${zIndexes.header};
      transition: background 0.3s ease-in-out;
    `};

  .header-inner {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    width: 1440px;
    .brand-logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      padding-left: 16px;
      padding-right: 13px;
      a {
        display: block;
        line-height: 0;
        .mobile-logo {
          width: 154px;
          height: 26px;
          @media (min-width: 1025px) {
            display: none;
          }
        }
      }

      @media (max-width: ${responsive.medium}) {
        padding: 0;
      }
    }
  }
  @media (max-width: ${responsive.medium}) {
    position: sticky;
    top: -1px;
    height: 51px;
    z-index: ${zIndexes.header};

    .header-inner {
      padding-left: 16px;
      padding-right: 11px;
    }

    ${props =>
      props.isTransparentHeader &&
      css`
        margin: 0 0 -51px;
      `};
  }
`;

const NAVI_MENU = [
  {
    name: '미니인턴',
    to: routes.project,
    gtmLabel: 'common_click_gnb_miniintern',
  },
  {
    name: '스킬업',
    to: routes.skillup,
    gtmLabel: 'common_click_gnb_skillup',
  },
  {
    name: 'M클래스',
    to: routes.event,
    gtmLabel: 'common_click_gnb_mclass',
  },
  {
    name: '채용관',
    to: routes.recruitment,
    gtmLabel: 'common_click_gnb_recruit',
  },
  {
    name: '해피폴리오',
    to: routes.happyfolio,
    gtmLabel: 'common_click_gnb_happyfolio',
  },
];

interface IProps {
  stickyHeader?: boolean;
  transparentHeader: boolean;
}

const Header: React.FC<IProps> = ({ stickyHeader, transparentHeader }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [sideStatus, toggleSidebar] = useState(false);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const clickToggleSidebar: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLOrSVGElement
  > = () => {
    toggleSidebar(!sideStatus);
  };

  const onClickMenuToggle = debounce(() => {
    setMenuVisible(!menuVisible);
  }, 100);

  const updateScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    if (transparentHeader) {
      window.addEventListener('scroll', updateScroll);
    }

    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  }, [transparentHeader]);

  const pathname = usePathname();
  useEffect(() => {
    toggleSidebar(false);
    setMenuVisible(false);
  }, [pathname]);

  return (
    <HeaderBlock
      isSticky={stickyHeader}
      isTransparentHeader={transparentHeader}
      isScrolled={transparentHeader && isScrolled}
      id="header"
    >
      <div className="header-inner">
        <div className="brand-logo">
          <Link href={routes.home} data-gtm-label="common_click_header_logo">
            <div className="visible-on-pc">
              {!transparentHeader || isScrolled ? <PCHeaderLogoSVG /> : <PCHeaderWhiteLogoSVG />}
            </div>
            <div className="visible-on-mobile">
              {!transparentHeader || isScrolled ? (
                <MobileHeaderLogoSVG />
              ) : (
                <MobileHeaderWhiteLogoSVG />
              )}
            </div>
          </Link>
        </div>

        <HeaderList type="NAVI">
          {NAVI_MENU.map(({ ...restProps }) => (
            <HeaderListItem
              key={restProps.name}
              {...restProps}
              transparentHeader={transparentHeader}
              isScrolled={transparentHeader && isScrolled}
            />
          ))}
        </HeaderList>

        <HeaderRightMenu
          menuVisible={menuVisible}
          onClickMenuToggle={onClickMenuToggle}
          clickToggleSidebar={clickToggleSidebar}
          transparentHeader={transparentHeader}
          isScrolled={transparentHeader && isScrolled}
        />
      </div>
      <div className="visible-on-mobile">
        <Sidebar sideStatus={sideStatus} clickToggleSidebar={clickToggleSidebar} />
      </div>
    </HeaderBlock>
  );
};

export default React.memo(Header);
