import { useCallback } from 'react';
import { AuthsType } from '#types/data';
import { logout } from '../api/user';
import { sendErrorToSentry } from '../error';
import useToaster from './useToaster';
import useUserInfo from './useUserInfo';
import { usePathname } from 'next/navigation';

const AUTH_INFO = {
  master: [
    'master',
    'service',
    'operator',
    'event',
    'companyOperator',
    'happyfolio',
    'magazine',
    'skillup',
  ],
  service: ['service', 'operator', 'event', 'companyOperator', 'happyfolio', 'magazine', 'skillup'],
  operator: ['operator', 'event', 'companyOperator', 'happyfolio', 'magazine', 'skillup'],
  event: ['event'],
  companyOperator: ['companyOperator'],
  happyfolio: ['happyfolio'],
  magazine: ['magazine'],
  skillup: ['skillup'],
};

const useAuth = () => {
  const pathname = usePathname();
  const { openToast } = useToaster();
  const { userInfo, mutateUserInfo } = useUserInfo();

  const onLogout = useCallback(async () => {
    try {
      await logout();
      mutateUserInfo(undefined);

      if (pathname !== '/secession') {
        openToast({
          type: 'success',
          message: '로그아웃 했습니다.',
          callback: () => {
            window.location.href = '/auth/login';
          },
        });
      }
    } catch (err) {
      const { response, statusText } = err;
      sendErrorToSentry(err);
      openToast({ type: 'error', message: response.data || statusText });
    }
  }, []);

  const checkHasAuth = useCallback(
    (checkAuth: AuthsType) => {
      if (userInfo && userInfo.admin) {
        return userInfo.admin.auths.some(auth => AUTH_INFO[auth].includes(checkAuth));
      }
      return false;
    },
    [userInfo],
  );

  return {
    onLogout,
    checkHasAuth,
  };
};

export default useAuth;
