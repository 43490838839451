import { ComponentProps } from 'react';
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper } from 'swiper/react';

export type SwiperProps = ComponentProps<typeof Swiper>;

export const addSwiperOption = (baseOption: SwiperProps, customOption: SwiperProps) => ({
  ...baseOption,
  ...customOption,
});

export const CARD_PAGINATION_SWIPER_OPTION: SwiperProps = {
  modules: [Pagination],
  slidesPerView: 'auto',
  pagination: { clickable: true },
  threshold: 10,
};

export const CARD_NAVIGATION_SWIPER_OPTION: SwiperProps = {
  modules: [Navigation],
  slidesPerView: 'auto',
  navigation: true,
  threshold: 10,
};

export const PDF_PREVIEW_SWIPER_OPTION: SwiperProps = {
  modules: [Navigation, Pagination, Thumbs],
  slidesPerView: 3,
  navigation: true,
  spaceBetween: 10,
  centeredSlides: true,
  pagination: {
    type: 'custom',
    renderCustom: (swiper, current, total) => {
      return current + ' - ' + total;
    },
  },
  breakpoints: {
    1024: {
      slidesPerView: 'auto',
      centeredSlides: false,
    },
    428: {
      slidesPerView: 3,
      centeredSlides: true,
    },
    0: {
      slidesPerView: 1,
    },
  },
};

export const PDF_PREVIEW_THUMBS_SWIPER_OPTION: SwiperProps = {
  freeMode: true,
  watchSlidesProgress: true,
  modules: [Thumbs, Navigation],
  threshold: 100,
  spaceBetween: 10,
  slidesPerView: 5,
};

export const PDF_IMAGES_SWIPER_OPTION: SwiperProps = {
  modules: [Navigation],
  centeredSlides: false,
  navigation: true,
  slidesPerView: 'auto',
  threshold: 10,
  breakpoints: { 375: { centeredSlides: true } },
};

export const HEAD_HUNTING_SWIPER_OPTION: SwiperProps = {
  pagination: {
    clickable: true,
  },
  slidesPerView: 'auto',
  spaceBetween: 15,
  modules: [Pagination],
  centeredSlides: true,
  threshold: 10,
};

export const BANNER_SWIPER_OPTION: SwiperProps = {
  speed: 500,
  autoplay: { delay: 3000, disableOnInteraction: false },
  loop: true,
  draggable: true,
  pagination: { clickable: true },
  preventClicks: false,
  preventClicksPropagation: false,
  modules: [Autoplay, Pagination],
  breakpoints: {
    1024: {
      touchRatio: 0,
    },
  },
  onSwiper: swiper => {
    if (swiper.slides.length <= 3) {
      swiper.autoplay.stop();
      swiper.allowTouchMove = false;
    }
  },
};

export const WITH_US_EXAMPLE_SWIPER_OPTION: SwiperProps = {
  modules: [Navigation, Pagination],
  navigation: {
    prevEl: '.withus-miniintern-examples-swiper-prev-button',
    nextEl: '.withus-miniintern-examples-swiper-next-button',
  },
  pagination: { el: '.withus-miniintern-examples-swiper-pagination', clickable: true },
  loop: true,
  speed: 300,
  spaceBetween: 15,
};

export const DEFAULT_SWIPER_OPTION: SwiperProps = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  freeMode: true,
  modules: [FreeMode],
};
