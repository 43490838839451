import React from 'react';
import styled from 'styled-components';
import responsive from '@miniintern/styles/responsive';

const HeaderListBlock = styled.nav<{ type: string }>`
  margin-left: ${props => (props.type === 'USER' ? 'auto' : null)};
  height: 100%;
  ul {
    height: 100%;
    display: flex;
  }
  @media (max-width: ${responsive.medium}) {
    display: none;
  }
`;

interface IProps {
  type: 'NAVI' | 'USER';
  children: React.ReactNode;
}

const HeaderList: React.FC<IProps> = ({ children, type }) => {
  return (
    <>
      {type === 'NAVI' ? (
        <HeaderListBlock type={type}>
          <ul>{children}</ul>
        </HeaderListBlock>
      ) : (
        <HeaderListBlock type={type} as="div">
          <ul>{children}</ul>
        </HeaderListBlock>
      )}
    </>
  );
};

export default React.memo(HeaderList);
