import React from 'react';
import styled from 'styled-components';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';
import FooterSiteInfo from './FooterSiteInfo';
import FooterCompanyInfo from './FooterCompanyInfo';

const FooterWrapperBlock = styled.footer`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 27px 0 37px;
  border-top: 1px solid ${palette.gray_300};
  background-color: ${palette.white};

  @media (max-width: ${responsive.medium}) {
    height: auto;
    padding: 24px 28px;
  }

  .footer-wrapper-inner {
    width: 930px;
    margin: 0 auto;

    @media (max-width: ${responsive.medium}) {
      width: 100%;
    }
  }
`;

const FooterWrapper: React.FC = () => {
  return (
    <FooterWrapperBlock id="footer-wrapper">
      <div className="footer-wrapper-inner">
        <FooterSiteInfo />
        <FooterCompanyInfo />
      </div>
    </FooterWrapperBlock>
  );
};

export default React.memo(FooterWrapper);
