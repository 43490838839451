import React from 'react';
import styled, { css } from 'styled-components';
import palette from '@miniintern/styles/palette';

const choiceChipColor = {
  blue_500: {
    default: css`
      color: ${palette.gray_800};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
    hover: css`
      color: ${palette.blue_500};
      background-color: ${palette.white};
      border: 1px solid ${palette.blue_500};
    `,
    active: css`
      color: ${palette.white};
      background-color: ${palette.blue_500};
      border: 1px solid ${palette.blue_500};
    `,
    disabled: css`
      color: ${palette.gray_500};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
  },
  rc_blue_500: {
    default: css`
      color: ${palette.gray_800};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
    hover: css`
      color: ${palette.rc_blue_500};
      background-color: ${palette.white};
      border: 1px solid ${palette.rc_blue_500};
    `,
    active: css`
      color: ${palette.white};
      background-color: ${palette.rc_blue_500};
      border: 1px solid ${palette.rc_blue_500};
    `,
    disabled: css`
      color: ${palette.gray_500};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
  },
  black: {
    default: css`
      color: ${palette.gray_800};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
    hover: css`
      color: ${palette.gray_800};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_800};
    `,
    active: css`
      color: ${palette.white};
      background-color: ${palette.gray_800};
      border: 1px solid ${palette.gray_800};
    `,
    disabled: css`
      color: ${palette.gray_500};
      background-color: ${palette.white};
      border: 1px solid ${palette.gray_400};
    `,
  },
};

const choiceChipSizeStyles = {
  large: css`
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
  `,
  medium: css`
    height: 28px;
    padding: 0 12px;
    font-size: 13px;
    font-weight: 500;
  `,
};

/**
 * ChoiceChip의 속성 타입 정의.
 *
 * 단일 선택만 가능합니다.
 */
export interface ChoiceChipProps extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * ChoiceChip의 색상.
   * - 'blue_500'는 구직자에서 사용됩니다.
   * - 'rc_blue_500'는 채용관에서 사용됩니다.
   * - 'black'는 기업에서 사용됩니다.
   *
   * @default blue
   */
  color?: 'blue_500' | 'rc_blue_500' | 'black';
  /**
   * ChoiceChip의 크기. 'large', 'medium' 중 하나를 선택할 수 있습니다.
   *
   * @default medium
   */
  size?: 'large' | 'medium';
  /**
   * ChoiceChip이 비활성화 상태인지 여부를 나타냅니다.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * ChoiceChip이 활성화 상태인지 여부를 나타냅니다.
   *
   * @default false
   */
  isActive?: boolean;
  /**
   * Chip 컴포넌트의 ref. 외부에서 전달된 ref와 내부 ref를 결합하여 사용합니다.
   */
  ref?: React.Ref<HTMLButtonElement>;
  /**
   * Chip 컴포넌트의 라벨.
   */
  label?: string;
  /**
   * Chip 컴포넌트를 클릭할 때 호출되는 함수. 클릭 이벤트를 처리합니다.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ChoiceChipBlock = styled.button<{
  size: 'large' | 'medium';
  color: 'blue_500' | 'rc_blue_500' | 'black';
  isActive: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 100px;

  ${({ size }) => choiceChipSizeStyles[size]}
  ${({ color, isActive }) =>
    isActive ? choiceChipColor[color].active : choiceChipColor[color].default}

  &:hover {
    ${({ color, isActive }) =>
      isActive ? choiceChipColor[color].active : choiceChipColor[color].hover}
    cursor: pointer;
  }

  &:active {
    ${({ color }) => choiceChipColor[color].active}
  }

  ${({ disabled, color }) =>
    disabled &&
    css`
      ${choiceChipColor[color].disabled}
      pointer-events: none;
    `}
`;

const ChoiceChip: React.FC<ChoiceChipProps> = ({
  label,
  isActive = false,
  size = 'medium',
  color = 'blue_500',
  disabled = false,
  ...props
}) => {
  return (
    <ChoiceChipBlock
      isActive={isActive}
      size={size}
      color={color}
      disabled={disabled}
      data-testid="choice-chip"
      {...props}
    >
      {label}
    </ChoiceChipBlock>
  );
};

export default ChoiceChip;
