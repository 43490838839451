import React from 'react';
import styled from 'styled-components';
import responsive from '@miniintern/styles/responsive';
import palette from '@miniintern/styles/palette';
import UserMenuList from './UserMenuList';
import { UserInfoType } from '#types/data';
import useAuth from '#lib/hooks/useAuth';
import Image from 'next/legacy/image';

const UserMenuWrapperBlock = styled.div<{
  transparentHeader: boolean;
  isScrolled: boolean;
}>`
  position: relative;
  user-select: none;
  margin-left: 16px;
  .user-profile-button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;

    .user-name {
      color: ${props =>
        props.transparentHeader && !props.isScrolled ? `${palette.white}` : `${palette.gray_800}`};
      margin-left: 10px;
    }
  }
  @media (max-width: ${responsive.medium}) {
    display: none;
  }
`;

interface IProps {
  userInfo: UserInfoType;
  menuVisible: boolean;
  onClickMenuToggle: () => void;
  transparentHeader: boolean;
  isScrolled: boolean;
}

const UserMenuWrapper: React.FC<IProps> = ({
  userInfo,
  onClickMenuToggle,
  menuVisible,
  transparentHeader,
  isScrolled,
}) => {
  // const { userInfo } = useUserInfo();
  const { checkHasAuth } = useAuth();
  const isMiniinternOperator = (
    ['operator', 'event', 'magazine', 'happyfolio', 'skillup'] as const
  ).some(v => checkHasAuth(v));

  const isRecruitmentAdmin = !!userInfo?.recruitmentAdmin;

  return (
    <UserMenuWrapperBlock transparentHeader={transparentHeader} isScrolled={isScrolled}>
      <button
        className="user-profile-button"
        onClick={onClickMenuToggle}
        role="presentation"
        data-gtm-label="common_click_profile"
      >
        <Image
          src={userInfo.profile.profileImage}
          alt="thumbnail"
          layout="fixed"
          width={32}
          height={32}
          style={{ borderRadius: '50%' }}
          objectFit="cover"
          unoptimized
        />
        <span className="user-name">{userInfo.profile.name}</span>
      </button>
      {menuVisible && (
        <UserMenuList
          isMiniinternOperator={isMiniinternOperator}
          isRecruitmentAdmin={isRecruitmentAdmin}
          onClickMenuToggle={onClickMenuToggle}
        />
      )}
    </UserMenuWrapperBlock>
  );
};

export default React.memo(UserMenuWrapper);
