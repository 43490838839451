import React from 'react';
import styled from 'styled-components';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';

const FooterCompanyInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 3;

  @media (max-width: ${responsive.medium}) {
    flex-direction: column;
  }

  .footer-wrapper-company-info-textbox {
    p {
      font-size: 12px;
      line-height: 18px;
      color: ${palette.gray_500};
      word-break: keep-all;
    }
  }
  a {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${palette.gray_500};

    @media (max-width: ${responsive.medium}) {
      margin: 16px 0 0;
    }
  }
`;

const FooterCompanyInfo = () => {
  return (
    <FooterCompanyInfoBlock>
      <div className="footer-wrapper-company-info-textbox">
        <p>(주)오픈놀 | 대표이사 : 권인택 | 이메일 : help@miniintern.com | 문의 : 070-8221-3056</p>
        <p>서울특별시 영등포구 양평로 2 주식회사 오픈놀 | 사업자등록번호 : 110-81-99930</p>
        <p>
          직업정보제공사업 : 서울서부 제2018-19호 | 통신판매업신고 : 제2021-서울영등포-2467호 |
          유료직업소개사업등록번호 : 제2021-3180239-14-5-00015호
        </p>
      </div>
      <a href="https://openknowl.com/">ⓒ OPENKNOWL</a>
    </FooterCompanyInfoBlock>
  );
};

export default FooterCompanyInfo;
