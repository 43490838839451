import React from 'react';
import HeaderListItem from './HeaderListItem';
import HeaderAlaram from './HeaderAlaram';
import HamburgerMenuSVG from '@svg/hamburger/hamburger_menu.svg';
import HamburgerWhiteMenuSVG from '@svg/hamburger/hamburger_menu-white.svg';
import UserMenuWrapper from '../userMenu/UserMenuWrapper';
import HeaderList from './HeaderList';
import useUserInfo from '#lib/hooks/useUserInfo';
import styled from 'styled-components';
import responsive from '@miniintern/styles/responsive';
import palette from '@miniintern/styles/palette';
import routes from '#constants/routes';
import Link from 'next/link';

const HeaderRightMenuBlock = styled.div<{
  transparentHeader: boolean;
  isScrolled: boolean;
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  padding-right: 24px;
  height: 100%;

  .alarm-icon {
    cursor: pointer;
  }
  .service-info-link-button {
    position: relative;
    padding: 20px 16px;
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;

    color: ${props =>
      !props.transparentHeader || props.isScrolled ? palette.gray_800 : `${palette.gray_400}`};

    transition: background 0.3s;
    @media (max-width: ${responsive.medium}) {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      z-index: 1;
      height: 2px;
      background: ${palette.blue_500};
      transform: scaleX(0);
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover {
      color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_800 : palette.white};
      background-color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_50 : 'transparent'};

      ${props =>
        (!props.transparentHeader || props.isScrolled) &&
        `
        &:after {
          opacity: 1;
          transform: scaleX(1);
        }
      `}
    }
    &.active {
      color: ${props =>
        !props.transparentHeader || props.isScrolled ? palette.gray_800 : palette.white};

      ${props =>
        (!props.transparentHeader || props.isScrolled) &&
        `
        &:after {
          opacity: 1;
          transform: scaleX(1);
        }
      `}
    }
  }

  .hamburger-menu {
    display: none;
  }
  @media (max-width: ${responsive.medium}) {
    padding-right: 0;

    .hamburger-menu {
      outline: none;
      border: 0;
      display: flex;
      padding: 0;
      margin-left: 12px;
      cursor: pointer;
    }
  }
`;

interface IProps {
  menuVisible: boolean;
  onClickMenuToggle: () => void;
  clickToggleSidebar: (
    event: React.MouseEvent<
      // eslint-disable-next-line no-undef
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLOrSVGElement
    >,
  ) => void;
  transparentHeader: boolean;
  isScrolled: boolean;
}

const RIGHT_MENU: { name: string; to: string; type?: 'USER'; gtmLabel: string }[] = [
  { name: '서비스 소개', to: routes.about, gtmLabel: 'common_click_gnb_service' },
  { type: 'USER', name: '회원가입', to: routes.signup, gtmLabel: 'common_click_gnb_signup' },
  { type: 'USER', name: '로그인', to: routes.login, gtmLabel: 'common_click_gnb_login' },
  { type: 'USER', name: '기업회원', to: routes.withus, gtmLabel: 'common_click_gnb_companyuser' },
];

const HeaderRightMenu: React.FC<IProps> = ({
  menuVisible,
  onClickMenuToggle,
  clickToggleSidebar,
  transparentHeader,
  isScrolled,
}) => {
  const { userInfo, isLoadingToGetUserInfo } = useUserInfo();

  if (isLoadingToGetUserInfo) return null;

  return (
    <HeaderRightMenuBlock transparentHeader={transparentHeader} isScrolled={isScrolled}>
      {!!userInfo ? (
        <>
          <Link
            href={routes.about}
            className="service-info-link-button"
            data-gtm-label="common_click_gnb_service"
          >
            서비스 소개
          </Link>
          <HeaderAlaram transparentHeader={transparentHeader} isScrolled={isScrolled} />
          {!transparentHeader || isScrolled ? (
            <HamburgerMenuSVG
              className="hamburger-menu"
              onClick={clickToggleSidebar}
              data-gtm-label="common_click_hamburger_menu"
            />
          ) : (
            <HamburgerWhiteMenuSVG
              className="hamburger-menu"
              onClick={clickToggleSidebar}
              data-gtm-label="common_click_hamburger_menu"
            />
          )}
          <UserMenuWrapper
            userInfo={userInfo}
            menuVisible={menuVisible}
            onClickMenuToggle={onClickMenuToggle}
            transparentHeader={transparentHeader}
            isScrolled={isScrolled}
          />
        </>
      ) : (
        <>
          {!transparentHeader || isScrolled ? (
            <HamburgerMenuSVG
              className="hamburger-menu"
              onClick={clickToggleSidebar}
              data-gtm-label="common_click_hamburger_menu"
            />
          ) : (
            <HamburgerWhiteMenuSVG
              className="hamburger-menu"
              onClick={clickToggleSidebar}
              data-gtm-label="common_click_hamburger_menu"
            />
          )}
          <HeaderList type="USER">
            {RIGHT_MENU.map(({ ...restProps }) => (
              <HeaderListItem
                key={restProps.name}
                {...restProps}
                transparentHeader={transparentHeader}
                isScrolled={isScrolled}
              />
            ))}
          </HeaderList>
        </>
      )}
    </HeaderRightMenuBlock>
  );
};

export default HeaderRightMenu;
