import React from 'react';
import styled from 'styled-components';
import ChoiceChip, { ChoiceChipProps } from './ChoiceChip';

const ChoiceChipGroupBlock = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

type Option = string | { value: string; label: string };

interface ChoiceChipGroupProps extends React.HTMLAttributes<HTMLUListElement> {
  /**
   * 선택 칩으로 표시될 옵션들의 배열
   *
   * @type {Option[]}
   */
  options: Option[];

  /**
   * 선택 칩의 색상
   *
   * @type {ChoiceChipProps['color']}
   */
  color?: ChoiceChipProps['color'];

  /**
   * 선택 칩의 크기
   *
   * @type {ChoiceChipProps['size']}
   */
  size?: ChoiceChipProps['size'];

  /**
   * 선택 칩 그룹의 비활성화 여부
   *
   * @type {boolean}
   */
  disabled?: boolean;

  /**
   * 현재 선택된 값
   *
   * @type {string}
   */
  selectedValue?: string;

  /**
   * 선택된 값을 업데이트하는 함수
   *
   * @type {(selectedValue: string) => void}
   */
  setValue?: (selectedValue: string) => void;
}

const ChoiceChipGroup: React.FC<ChoiceChipGroupProps> = ({
  options,
  color = 'blue_500',
  size = 'medium',
  disabled = false,
  selectedValue,
  setValue,
  ...props
}) => {
  const getOptionLabel = (option: Option): string => {
    return typeof option === 'string' ? option : option.label;
  };

  const getOptionValue = (option: Option): string => {
    return typeof option === 'string' ? option : option.value;
  };

  const handleChipClick = (option: Option, event: React.MouseEvent) => {
    event.preventDefault();

    const value = getOptionValue(option);
    setValue?.(value);
  };

  return (
    <ChoiceChipGroupBlock {...props} data-testid="choice-chip-group">
      {options.map(option => (
        <ChoiceChip
          key={getOptionValue(option)}
          label={getOptionLabel(option)}
          isActive={selectedValue === getOptionValue(option)}
          onClick={event => handleChipClick(option, event)}
          aria-pressed={selectedValue === getOptionValue(option)}
          color={color}
          size={size}
          disabled={disabled}
        />
      ))}
    </ChoiceChipGroupBlock>
  );
};

export default ChoiceChipGroup;
